<mat-toolbar class="header">
  <div class="logo-width">
    <a mat-button routerLink="/overview">
      <img class="logo-nav" src="assets/img/Bankhaus_Bauer_Logo.svg" alt="logo" />
    </a>
  </div>
  <span class="text-logo d-none d-xl-block">Bankhaus Bauer Backend</span>

  <div class="ml-auto">
    <a mat-button routerLink="/admin/users" routerLinkActive="active" *ngIf="!isClient">USERS</a>
    <a mat-button routerLink="/admin/importuser" routerLinkActive="active" *ngIf="isAdmin">IMPORT USER</a>
    <a mat-button routerLink="/admin/emails" routerLinkActive="active" *ngIf="isAdminBB">EMAILS</a>
    <a mat-button routerLink="/admin/translation" routerLinkActive="active" *ngIf="isAdminBB">TRANSLATION</a>

    <mat-menu #appMenu="matMenu">
      <button mat-menu-item (click)="showMaintinanceModal()" *ngIf="isAdmin">
        <span translate="DEPOT.NAV_BUTTON_MAINTINANCE"></span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logout()">
        <span translate="Logout"></span>
      </button>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</mat-toolbar>
<div class="row g-0 ps-5 pt-4">
  <div class="col-11">
    <router-outlet></router-outlet>
  </div>
</div>
