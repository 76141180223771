import { AuthService } from 'src/app/services/auth.service';
import { QuestionService } from 'src/app/services/question.service';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { QuestionTemplate } from '../question-template';
import { DefaultDialogBoxComponent } from 'src/app/component/dialog-boxes/default-dialog-box/default-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslationDirective } from '../../../../component/translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgClass } from '@angular/common';
import { InfoCardComponent } from '../../../../component/info-card/info-card.component';

@Component({
    selector: 'app-depot-bank-wahl',
    templateUrl: './depotbank-wahl.component.html',
    styleUrls: ['./depotbank-wahl.component.scss'],
    standalone: true,
    imports: [
        TranslationDirective,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatCheckbox,
        MatIcon,
        NgIf,
        NgClass,
        InfoCardComponent,
    ],
})
export class DepotbankWahlComponent extends QuestionTemplate implements OnInit {
  selectedBank: string;

  constructor(
    auth: AuthService,
    questionService: QuestionService,
    route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    super(auth, questionService, route);
    this.questionName = 'bank-wahl';
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.createFormGroup();
    this.setupAbtretungsvertragBehavior();
    this.loadValues();
  }

  private createFormGroup(): void {
    const abtretungsvertragValidators = this.auth.user.lang === 'odfin' ? [Validators.required] : [];

    this.answerFormGroup = new FormGroup({
      geschäftKonto: new FormControl({ value: true, disabled: true }, [Validators.required]),
      kreditKarte: new FormControl(false),
      kontokorrentkredit: new FormControl(false),
      abtretungsvertrag: new FormControl({ value: false, disabled: true }, abtretungsvertragValidators),
      kontoWechselService: new FormControl(false),
      girocard: new FormControl({ value: true, disabled: true }, [Validators.required]),
      onlineBanking: new FormControl({ value: true, disabled: true }, [Validators.required]),
      elektronischesPostfach: new FormControl({ value: true, disabled: true }, [Validators.required]),
      firmenlastschrift: new FormControl({ value: true, disabled: true }, [Validators.required]),
    });
  }

  private setupAbtretungsvertragBehavior(): void {
    if (this.auth.user.lang !== 'odfin') {
      return;
    }

    this.answerFormGroup.get('abtretungsvertrag')!.disable();

    this.answerFormGroup.get('kontokorrentkredit')!.valueChanges.subscribe((value) => {
      if (value) {
        this.showConfirmationDialog(
          'PRODUKTAUSWAHL.BESTÄTIGUNG_PRODUKTAUSWAHL_ABTRETUNGSVERTRAGHINWEISE_HEADER',
          'PRODUKTAUSWAHL.BESTÄTIGUNG_PRODUKTAUSWAHL_ABTRETUNGSVERTRAGHINWEISE_TEXT',
          () => {
            this.answerFormGroup.get('abtretungsvertrag')!.enable();
          }
        );
      } else {
        this.answerFormGroup.get('abtretungsvertrag')!.disable();
      }
    });
  }

  private async showConfirmationDialog(headerText: string, subText: string, onConfirm: () => void): Promise<void> {
    const answer = await this.dialog
      .open(DefaultDialogBoxComponent, {
        data: { headerText, subText },
      })
      .afterClosed()
      .toPromise();

    if (answer === true) {
      this.answerFormGroup.controls['abtretungsvertrag'].setValue(true);
      onConfirm();
    } else {
      this.answerFormGroup.controls['kontokorrentkredit'].setValue(false);
    }
  }

  onKontokorrentkreditChange(event: any): void {
    if (this.auth.user.lang === 'odfin' && !event.checked) {
      this.answerFormGroup.controls['abtretungsvertrag'].setValue(false);
    }
  }

  showWarningMessage(): boolean {
    if (this.auth.user.lang === 'odfin') {
      const kontokorrentkredit = this.answerFormGroup.get('kontokorrentkredit')?.value;
      const abtretungsvertrag = this.answerFormGroup.get('abtretungsvertrag')?.value;
      return kontokorrentkredit && !abtretungsvertrag;
    }
    return false;
  }

  isMcc(): boolean {
    return this.auth.user.lang === 'mcc';
  }
}
