<h2 class="text-primary" appTranslationModal [translate]="'PRODUKTAUSWAHL.FORMULAR_TITLE'"></h2>

<form class="card-container" [formGroup]="answerFormGroup">
  <mat-accordion multi="false">
    <mat-expansion-panel>
      <mat-expansion-panel-header style="padding: 1.5rem">
        <mat-panel-title class="ps-2">
          <mat-checkbox formControlName="geschäftKonto">
            <p class="title-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO'">
              <!-- Ihr Geschäftskonto bei Bankhaus Bauer -->
            </p>
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-3">
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_VORTEILE'"
        ></p>
        <br />
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_VORTEIL1'"
          ></p>
        </div>
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_VORTEIL2'"
          ></p>
        </div>
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_VORTEIL3'"
          ></p>
        </div>
        <br /><br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_AUFWUNSCH'"
        ></p>

        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_WUNSCH1'"
        ></p>

        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_WUNSCH2'"
        ></p>
        <br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_PREIS'"
        ></p>
        <br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_EURO'"
        ></p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="isMcc()">
      <mat-expansion-panel-header>
        <mat-panel-title class="ps-2">
          <mat-checkbox formControlName="firmenlastschrift">
            <p class="title-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.FIRMENLASTSCHRIFT'"></p>
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-3">
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.FIRMENLASTSCHRIFT_BESCHREIBUNG_VORTEILE'"
        ></p>
        <br />
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.FIRMENLASTSCHRIFT_BESCHREIBUNG_VORTEIL1'"
          ></p>
        </div>
        <br /><br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.FIRMENLASTSCHRIFT_BESCHREIBUNG_PREIS'"
        ></p>
        <br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.FIRMENLASTSCHRIFT_BESCHREIBUNG_EURO'"
        ></p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="ps-2">
          <mat-checkbox formControlName="onlineBanking">
            <p class="title-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING'"></p>
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-3">
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING_BESCHREIBUNG_VORTEILE'"
        ></p>
        <br />
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING_BESCHREIBUNG_VORTEIL1'"
          ></p>
        </div>
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING_BESCHREIBUNG_VORTEIL2'"
          ></p>
        </div>
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING_BESCHREIBUNG_VORTEIL3'"
          ></p>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="ps-2">
          <mat-checkbox formControlName="elektronischesPostfach">
            <p class="title-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH'"></p>
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-3">
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEILE'"
        ></p>
        <br />
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL1'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL2'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL3'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL4'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL5'"
          ></p>
        </div>
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL6'"
          ></p>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="ps-2">
          <mat-checkbox formControlName="girocard">
            <p class="title-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD'"></p>
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-3">
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEILE'"
        ></p>
        <br />
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL1'"
          ></p>
        </div>
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL2'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL3'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL4'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL5'"
          ></p>
        </div>
        <br /><br />
        <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_PREIS'"></p>
        <br />
        <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_EURO'"></p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="ps-2">
          <mat-checkbox formControlName="kreditKarte">
            <p
              style="font-size: 20px; font-weight: 600; padding: 3px 0px"
              appTranslationModal
              [translate]="'PRODUKTAUSWAHL.KREDITKARTE'"
            ></p>
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-3">
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_VORTEILE'"
        ></p>
        <br />
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_VORTEIL1'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_VORTEIL2'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_VORTEIL3'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_VORTEIL4'"
          ></p>
        </div>
        <br /><br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_PREIS'"
        ></p>
        <br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_EURO'"
        ></p>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="ps-2">
          <mat-checkbox formControlName="kontokorrentkredit" (change)="onKontokorrentkreditChange($event)">
            <p class="title-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT'"></p>
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-3">
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_VORTEILE'"
        ></p>
        <br />
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_VORTEIL1'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_VORTEIL2'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_VORTEIL3'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_VORTEIL4'"
          ></p>
        </div>
        <br /><br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_KONDITIONEN'"
        ></p>
        <br />

        <p
          class="description-text"
          style="display: inline-block"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_KONDITION1'"
        ></p>
        <div style="display: inline-block; margin-left: 0.5rem" [translate]="'Zinsen.Sollzinssatz'"></div>
      </div>

      <div *ngIf="user.lang === 'odfin'" class="line-wrapper-start">
        <mat-checkbox class="ps-2" formControlName="abtretungsvertrag">
          <p
            class="title-text"
            [ngClass]="{ 'disabled-text': answerFormGroup.get('abtretungsvertrag')?.disabled }"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.ABTRETUNGSVERTRAG'"
          ></p>
        </mat-checkbox>

        <app-info-card>
          <div class="text-container">
            <div class="text-content" appTranslationModal [translate]="'PRODUKTAUSWAHL.ABTRETUNGSVERTRAGINFO'"></div>
          </div>
        </app-info-card>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title class="ps-2">
          <mat-checkbox formControlName="kontoWechselService">
            <p class="title-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE'"></p>
          </mat-checkbox>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="p-3">
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_VORTEILE'"
        ></p>
        <br />
        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_VORTEIL1'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_VORTEIL2'"
          ></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon class="check-icon">check</mat-icon>
          <p
            class="description-text"
            appTranslationModal
            [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_VORTEIL3'"
          ></p>
        </div>
        <br /><br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_PREIS'"
        ></p>
        <br />
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_EURO'"
        ></p>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="pt-2">
    <span appTranslationModal [translate]="'PRODUKTAUSWAHL.BOTTOM_TEXT'"></span>
    <span>&nbsp;</span>
    <span>
      <a
        target="_blank"
        href="https://bankhausbauer.de/websites/bankhausbauer.de/media/pages/mediastore/2ab5fbcbd2-1668159329/preisverzeichnis.pdf"
        >Preis- und Leistungsverzeichnis</a
      >
    </span>
    <br />
    <br />

    <span
      *ngIf="showWarningMessage() && user.lang === 'odfin'"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.ABTRETUNGSVERTRAGHINWEISE_ERRORTEXT'"
      style="color: red; margin-top: 10px; margin-right: 20px"
    >
    </span>
  </div>
</form>
