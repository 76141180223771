import { Question } from './question';

export enum Rol {
  adminBB = 'ADMINBB',
  admin = 'ADMIN',
  consultant = 'BERATER',
  client = 'KUNDE',
  superConsultant = 'BERATERBB',
}

export enum Status {
  active = 'active',
  inactive = 'inactive',
  finish = 'finish',
}

export interface BankAcount {
  bank: string;
  iban: string;
  accountId: string;
  accountHolder: string;
  accountNumber: string;
  accountName: string;
  accountType: string;
}

export class NatPerson {
  _id: string;
  BBKundennummer: Number;
  Mandatref: Number;
  Mandatkennung: string;
  Firmenname: string;
  Rechtsform: string;
  CrefoID: string;
  SteuerID: string;
  ErsterBelegeingang: Date;
  Risikokennzeichen: string;
  Abtretung: String;
  UeberleitungsID: String;
  BranchenInfo: {
    Fachgebiet: string;
    Art: string;
  };
  Person: {
    Personenart: string;
    Kundenart: string;
    Anrede: string;
    Titel: string;
    Vorname: string;
    Nachname: string;
    Briefanrede: string;
    Geburtsname: string;
    Geburtsdatum: string;
    Geburtsort: string;
    Geburtsland: string;
    RechtsformAuspraegung: string;
    Position: string;
    VollmachtKontoeroeffnung: Boolean;
    Vertretungsberechtigt: Boolean;
    Wirtschaftlichberechtigt: Boolean;
    FiktivWirtschaftlichberechtigt: Boolean;
  };
  Legitimationsdaten: {
    Ausweisart: string;
    Ausweisnummer: string;
    AusstellerLand: string;
    AusstellerBehoerde: string;
    AusgestelltAm: string;
    GueltigBis: string;
    GeprueftAm: string;
    GeprueftVonID: string;
    AusfOrganisation: string;
  };
  Dokumente: {
    Ausweiskopie: string;
    EinverstaendnisErklaerungDatenaustausch: string;
  };
  Adresse: {
    PLZ: string;
    Ort: string;
    Strasse: string;
    Land: string;
  };
  Firmenanschrift: {
    PLZ: string;
    Ort: string;
    Strasse: string;
    Land: string;
  };
  Kontakt: {
    Telefon: string;
    Email: string;
  };
}

export class Dokument {
  fileId: string;
  Base64: string;
  FileName: string;
  TS: string;
}

export class JurPerson {
  JurDaten: {
    BBKundennummer: Number;
    Personenart: String;
    Kundenart: String;
    Anrede: String;
    Briefanrede: String;
    NamePraegekarte: String;
    NamePraegekarte2: String;
    Firmenname: String;
    Kurzname: String;
    RechtsformAuspraegung: String;
    GbrArt: String;
  };
  Registerdaten: {
    Registerart: String;
    Registergericht: String;
    Registernummer: String;
    EintragDatum: String;
    RegAuszugDatum: String;
    RegGeprueftVon: String;
    RegGeprueftAm: String;
  };
  Adresse: {
    PLZ: String;
    Ort: String;
    Strasse: String;
    Land: String;
  };
  Kontaktdaten: {
    Kontakt: {
      Telefon: String;
      Email: String;
    };
  };
  Dokumente: {
    Handelsregisterauszug: [Dokument];
    Transparenzregister: [Dokument];
    Gesellschafterliste: [Dokument];
    Gesellschaftsvertrag: [Dokument];
    AusweiskopieVertretungsberechtigten: [Dokument];
    Gewerberegister: [Dokument];
    Gesellschafterregister: [Dokument];
    AusweiskopieWB: [Dokument];
    Nachweis_bei_WB: [Dokument];
  };
}
export class User {
  _id: string;
  name: string;
  lastname: string;
  customInfo: string;
  email: string;
  iban: string;
  kundenNummer: string;
  giroCardOrdered: boolean;
  status: Status;
  finapiProcessToken: string;
  finapiStatus: string;
  one: {
    institutionId: string;
    orderId: string;
    businessCaseId: string;
    userId: string;
  };
  finapi: {
    counter: number;
    selectedAccountList: BankAcount[];
    creditCheckPdf: {};
    creditCheck: {
      status: string;
      decisionCredit: string;
      decisionAccount: string;
      decision: string;
      derivedCreditLimitAmount: number;
      derivedCreditCardLimitAmount: number;
      creditBureauDataSchufa: {};
      creditBureauDataCrefo: {};
    };
    transactions: [];
    identityCheck: [{}];
    payload: { errorCode: string; errorMessage: string };
  };
  lastUpdateDate: Date;
  createAt: Date;
  firstTimeStatusActive: Date;
  firstTimeSubStatusInProcess: Date;
  dateStatusFinish: Date;
  questions: Array<Question>;
  kreditKarte: boolean;
  kontokorrentkredit: boolean;
  kontoWechselService: boolean;
  rol: string;
  isJurPerson: boolean;
  natPersonen: Array<NatPerson>;
  JurPerson: JurPerson;
  lang: string;
  emails: {
    date: Date;
    function: string;
  }[];
  documents: [
    {
      fileId: string;
      documentname: string;
      formularnummer: string;
      ablagenummer: number;
      documentdata: any;
      isIban: boolean;
    },
  ];
  blockedUser: boolean;

  constructor(obj?: any) {
    this.questions = new Array();
    this.rol = Rol.client;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}
